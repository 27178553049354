<template>
  <div :class="['loading', props.size]">
    <div class="loading__pulse">
      <sync-loader :loading="true" color="#888888" size="22.5px"></sync-loader>
    </div>
  </div>
</template>

<script setup>
import SyncLoader from 'vue-spinner/src/SyncLoader.vue';

const props = defineProps({
  size: {
    type: String,
    required: false,
    default: () => 'large',
  },
});
</script>
